import type { AuthProvider } from '@topo-io/supabase';

const production = process.env.NODE_ENV === 'production';

enum Themes {
  Light = 'light',
  Dark = 'dark',
}

const configuration = {
  site: {
    name: 'Topo Agent',
    description: 'Outbound sales on autopilot with AI',
    themeColor: '#ffffff',
    themeColorDark: '#0a0a0a',
    siteUrl: process.env.NEXT_PUBLIC_SITE_URL,
    siteName: 'Topo Agent',
    locale: 'en',
  },
  auth: {
    // NB: Enable the providers below in the Supabase Console
    // in your production project
    providers: {
      emailPassword: true,
      phoneNumber: false,
      emailLink: false,
      emailOtp: false,
      oAuth: ['google'] as AuthProvider[],
    },
  },
  production,
  environment: process.env.ENVIRONMENT,
  theme: Themes.Light,
  features: {
    enableThemeSwitcher: true,
  },
  paths: {
    signIn: '/auth/sign-in',
    signUp: '/auth/sign-up',
    onboardingPrefix: '/onboarding',
    newOnboarding: '/onboarding/new',
    appPrefix: '',
    appHome: '/',
    authCallback: '/auth/callback',
    templates: 'templates',
    campaigns: 'campaigns',
    activityFeed: 'activity_feed',
    emails: {
      new: 'emails/new',
      review: 'emails/review',
      contacted: 'emails/contacted',
    },
    settings: {
      profile: 'settings/profile',
      organization: 'settings/organization',
      calendar: 'settings/organization/calendar',
      agents: 'settings/organization/agents',
      members: 'settings/organization/members',
      email: 'settings/profile/email',
      password: 'settings/profile/password',
      crm: 'settings/organization/crm',
    },
  },
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
};

export default configuration;
